import React from 'react'
import Layout from '../components/layout'
import Products from '../components/Products'
import Seo from '../components/seo'

const AntsleAppliances = () => (
    <>
    <Layout>
        <Seo title="Antsle Appliances" />
        <Products />
    </Layout>
    </>
)

export default AntsleAppliances